<template>
  <div class="spmenu">
    <div class="hamburger" v-if="isShowButton" :class="getActiveClass" @click="onClickSpMenuButton">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <transition name="menubg">
      <div v-if="isShowMenu" class="spmenu-container" />
    </transition>
    <div class="spmenu-menu">
      <transition name="menu1">
        <div v-if="isShowMenu" class="spmenu-menu-label" :style="styleLine('top')"><a @click="clickTop">top</a></div>
      </transition>
      <transition name="menu2">
        <div v-if="isShowMenu" class="spmenu-menu-label" :style="styleLine('news')"><a @click="clickNews">news</a></div>
      </transition>
      <transition name="menu3">
        <div v-if="isShowMenu" class="spmenu-menu-label" :style="styleLine('about')">
          <a @click="clickAbout">about</a>
        </div>
      </transition>
      <transition name="menu4">
        <div v-if="isShowMenu" class="spmenu-menu-label" :style="styleLine('business')">
          <a @click="clickBusiness">business</a>
        </div>
      </transition>
      <transition name="menu5">
        <div v-if="isShowMenu" class="spmenu-menu-label" :style="styleLine('recruit')">
          <a @click="clickSns">sns</a>
        </div>
      </transition>
      <transition name="menu6">
        <div v-if="isShowMenu" class="spmenu-menu-label" :style="styleLine('recruit')">
          <a @click="clickRecruit">recruit</a>
        </div>
      </transition>
      <transition name="menu7">
        <div v-if="isShowMenu" class="spmenu-menu-label" :style="styleLine('contact')">
          <a @click="clickContact">contact</a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isShowButton: true,
      isShowMenu: false,
      isOpening: false,
    };
  },
  computed: {
    ...mapState(['viewPage']),
    getActiveClass() {
      return {
        active: this.isShowMenu,
      };
    },
  },
  methods: {
    onClickSpMenuButton() {
      this.isShowMenu = !this.isShowMenu;
    },
    styleLine(path) {
      if (this.viewPage == path) {
        return {
          'text-decoration': 'underline',
        };
      }
      return '';
    },
    btnAnim() {
      this.isShowButton = false;
      setTimeout(() => {
        this.isShowButton = true;
      }, 800);
    },
    clickTop() {
      if (this.viewPage == 'top') {
        return;
      }
      this.onClickSpMenuButton();
      this.$emit('goToTop');
      this.btnAnim();
    },
    clickNews() {
      if (this.viewPage == 'news') {
        return;
      }
      this.onClickSpMenuButton();
      this.$emit('goToNews');
      this.btnAnim();
    },
    clickAbout() {
      if (this.viewPage == 'about') {
        return;
      }
      this.onClickSpMenuButton();
      this.$emit('goToAbout');
      this.btnAnim();
    },
    clickBusiness() {
      if (this.viewPage == 'business') {
        return;
      }
      this.onClickSpMenuButton();
      this.$emit('goToBusiness');
      this.btnAnim();
    },
    clickSns() {
      this.$emit('goToSns');
    },
    clickRecruit() {
      if (this.viewPage == 'recruit') {
        return;
      }
      this.onClickSpMenuButton();
      this.$emit('goToRecruit');
      this.btnAnim();
    },
    clickContact() {
      if (this.viewPage == 'contact') {
        return;
      }
      this.onClickSpMenuButton();
      this.$emit('goToContact');
      this.btnAnim();
    },
    goToFooterAction() {
      this.btnAnim();
    },
  },
};
</script>

<style lang="scss" scoped>
.spmenu {
  &-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: red;
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  &-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
  }

  &-menu {
    position: fixed;
    top: 140px;
    width: 100%;
    padding: 40px;

    &-label {
      width: 100%;
      text-align: center;
      font-size: 2em;
      margin: 20px 0;
      font-weight: bold;
    }
  }

  a:visited {
    color: inherit !important;
  }

  a {
    color: inherit !important;
    // text-decoration: underline;
  }
}

.menubg-enter-active {
  transition: all 0.5s ease;
}
.menubg-leave-active {
  animation: anim-out 0.8s;
}
.menubg-enter {
  opacity: 0;
  transform: translateY(-800px);
}

@keyframes anim-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  40% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-800px);
  }
}

.menu1-enter-active {
  animation: anim1-in 0.8s;
}
.menu1-leave-active {
  animation: anim1-out 1s;
}
.menu2-enter-active {
  animation: anim2-in 0.8s;
}
.menu2-leave-active {
  animation: anim2-out 1s;
}
.menu3-enter-active {
  animation: anim3-in 0.8s;
}
.menu3-leave-active {
  animation: anim3-out 1s;
}
.menu4-enter-active {
  animation: anim4-in 0.8s;
}
.menu4-leave-active {
  animation: anim4-out 1s;
}
.menu5-enter-active {
  animation: anim5-in 0.8s;
}
.menu5-leave-active {
  animation: anim5-out 1s;
}
.menu6-enter-active {
  animation: anim6-in 0.8s;
}
.menu6-leave-active {
  animation: anim6-out 1s;
}
.menu7-enter-active {
  animation: anim7-in 0.8s;
}
.menu7-leave-active {
  animation: anim7-out 1s;
}

@keyframes anim1-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  30% {
    opacity: 0;
    transform: translateY(-30px);
  }
  40% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes anim2-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  40% {
    opacity: 0;
    transform: translateY(-30px);
  }
  50% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes anim3-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  50% {
    opacity: 0;
    transform: translateY(-30px);
  }
  60% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes anim4-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  60% {
    opacity: 0;
    transform: translateY(-30px);
  }
  70% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes anim5-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  70% {
    opacity: 0;
    transform: translateY(-30px);
  }
  80% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes anim6-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  80% {
    opacity: 0;
    transform: translateY(-30px);
  }
  90% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes anim7-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  90% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes anim1-out {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  10%,
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
@keyframes anim2-out {
  5% {
    opacity: 1;
    transform: translateY(0px);
  }
  15%,
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
@keyframes anim3-out {
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  20%,
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
@keyframes anim4-out {
  15% {
    opacity: 1;
    transform: translateY(0px);
  }
  25%,
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
@keyframes anim5-out {
  20% {
    opacity: 1;
    transform: translateY(0px);
  }
  30%,
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
@keyframes anim6-out {
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  35%,
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
@keyframes anim7-out {
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
  34%,
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

// -------------------------------
// -------------------------------
// -------------------------------
.hamburger {
  display: block;
  position: fixed;
  z-index: 3;
  right: 20px;
  top: 20px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  text-align: center;
  // background-color: rgba(232, 232, 232, 0.7);
  border-radius: 10px;
}
// .hamburger.active {
//   background-color: rgba(232, 232, 232, 0);
// }
.hamburger span {
  display: block;
  position: absolute;
  width: 30px;
  height: 4px;
  left: 6px;
  background: #0c0c0c;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  border-radius: 4px;
}
.hamburger span:nth-child(1) {
  top: 10px;
}
.hamburger span:nth-child(2) {
  top: 20px;
}
.hamburger span:nth-child(3) {
  top: 30px;
}

/* スマホメニューを開いてる時のボタン */
.hamburger.active span:nth-child(1) {
  top: 20px;
  left: 6px;
  // background: #fff;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger.active span:nth-child(2),
.hamburger.active span:nth-child(3) {
  top: 20px;
  left: 6px;
  // background: #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
